import { DateUtil } from '@tms/utils'

const MINUTE = 60
const HOUR = 60 * 60
const DAY = 24 * 60 * 60
const DAY_OF_TEN = 10 * 24 * 60 * 60

export function convertDate (date: number) {
  if (!date) { return '' }

  const currentDate = new Date()
  const targetDate = new Date(date * 1000)

  const diff = Math.floor((currentDate.getTime() - targetDate.getTime()) / 1000)
  if (diff < HOUR) {
    const minute = Math.floor(diff / MINUTE)
    return `${minute}분 전`
  } else if (diff < DAY) {
    const hours = Math.floor(diff / HOUR)
    return `${hours}시간 전`
  } else if (diff <= DAY_OF_TEN) {
    const days = Math.floor(diff / DAY)
    return `${days}일 전`
  } else {
    return DateUtil.convertDateFormat({
      date: targetDate,
      format: 'yyyy.MM.dd'
    })
  }
}

export function convertCommaToList (commaList: string, maxCount: number = 999999) {
  if (!commaList) {
    return []
  }
  return commaList.split(',').map((item: string) => item.trim()).splice(0, maxCount)
}

export const convertComma = (num: number) => {
  if (!num) {
    return '0'
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
